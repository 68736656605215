// Pembee
export const pembeeEnv = process.env.NEXT_PUBLIC_PEMBEE_ENV || "";
export const isDevSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "dev";
export const isDemoSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "demo";
export const isLocalSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "local";
export const isProdSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "prod";

// Stripe
export const stripePublishableKey =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || "";

// Help Scout
export const helpScoutBeaconId =
  process.env.NEXT_PUBLIC_HELPSCOUT_BEACON_ID || "";

// HMRC
export const hmrcClientId = process.env.NEXT_PUBLIC_HMRC_CLIENT_ID || "";
export const hmrcApiBaseUrl = process.env.NEXT_PUBLIC_HMRC_API_BASE_URL || "";
export const hmrcEppId = process.env.NEXT_PUBLIC_HMRC_EPP_ID || "";
export const hmrcEppReference =
  process.env.NEXT_PUBLIC_HMRC_EPP_REFERENCE || "";
export const hmrcOauthRedirectUri =
  process.env.NEXT_PUBLIC_HMRC_OAUTH_REDIRECT_URI || "";
