import type { AddOn, Ticket } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { SelectedAttendee } from "types/model/cart";
import type { DiscountRule } from "types/model/discount-rule";
import type { LineItem } from "types/model/line-item";
import type { PaymentTransaction } from "types/model/payment";
import type {
  SessionPass,
  SessionPassTransaction
} from "types/model/session-pass";
import type { User } from "types/model/user";

export interface BookingNote<U = string> {
  _id?: string;
  addedByUser: U;
  note: string;
  created?: Date;
}

export interface Booking<C = string, U = string> {
  _id: string;
  bookingNumber: number;
  lineItems: LineItem[];
  status: BookingStatus;
  user: U;
  created: Date;
  updated: Date;
  completedDate: Date;
  client: C;
  paymentTransactions: PaymentTransaction[];
  abandonedCartEmailSent: boolean;
  invoiceNumber?: string;
  total?: number;
  balance?: number;
  notes: BookingNote<User>[];
  selectedAttendees: SelectedAttendee[];
  save: () => Promise<void>;
  deleteOne: () => Promise<void>;
  toObject: () => Booking;
}

export interface BookingExportItem extends Booking<string, User> {
  user_info: User;
}

export interface LineItemExportItem extends Booking<string, User> {
  activityGroup: ActivityGroup;
  lineitems: LineItem;
  user_info: User;
  attendee: Attendee;
  discountrule: DiscountRule;
  sessionPassDebitData: SessionPass;
  activityAddonData: AddOn;
  sessionPassCredit: SessionPassTransaction<
    SessionPass,
    string,
    string,
    string
  >;
  ticketInfo: Ticket[];
  bookingTotal: number; // Currently only included for 'financial report'
}

export enum BookingStatus {
  Session = "session",
  Pending = "pending",
  Processing = "processing",
  Completed = "completed",
  Abandoned = "abandoned",
  Cancelled = "cancelled"
}

export enum BookingBalance {
  BalanceOutstanding = "balanceOutstanding",
  FullyPaid = "fullyPaid"
}

export type BookingTotalAndBalance = Array<{
  lineitem_total: number;
  lineitem_subtotal: number;
  Balance: number;
  accountDebit_total: number;
}>;

export interface AddOnBookingAddOnItem<P = string> {
  _id: string;
  activityGroup: string;
  activity: string;
  addOn: AddOn;
  parent: P;
}

export interface AddOnBooking<B = string, P = string> {
  _id: string;
  booking: B;
  user: string;
  addOnItems: AddOnBookingAddOnItem<P>[];
  stripePaymentIntentId: string;
  status: AddOnBookingStatus;
  client: string;
  total: number;
  accountCreditToApply?: number;
  save: () => Promise<void>;
  toObject: () => AddOnBooking;
}

export enum AddOnBookingStatus {
  Pending = "pending",
  Completed = "completed"
}

export enum BookingSummaryContext {
  Cart = "cart",
  CheckoutUser = "checkoutUser",
  CheckoutAttendee = "checkoutAttendee",
  CheckoutPayment = "checkoutPayment",
  CheckoutComplete = "checkoutComplete",
  Account = "account"
}

export interface AddBookingNoteData {
  bookingId: string;
  note: string;
}
