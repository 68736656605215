import { Button } from "components/ui/button";
import type { ComponentProps } from "react";
import { cn } from "utils/cn";

export const SubmitButton = ({
  disabled,
  isLoading,
  children,
  variant = "primary",
  size = "lg",
  type = "submit",
  isFullWidth = false,
  showSpinner = true,
  className,
  cy,
  form,
  ...props
}: {
  isLoading?: boolean;
  isFullWidth?: boolean;
  showSpinner?: boolean;
  cy?: string;
} & ComponentProps<typeof Button>) => {
  const isWhite = variant === "white";

  return (
    <Button
      {...(form ? { form } : {})}
      disabled={disabled}
      size={size}
      variant={variant}
      className={cn(
        "relative transition-opacity",
        isFullWidth && "w-full",
        disabled && "pointer-events-none opacity-50",
        className
      )}
      type={type}
      data-cy={cy || "btn-submit"}
      {...props}
    >
      <span className={cn(isLoading && showSpinner && "invisible")}>
        {children}
      </span>
      {showSpinner && isLoading && (
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke={isWhite ? "#4f46e5" : "#ffffff"}
          style={{ width: 19, height: 19 }}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      )}
    </Button>
  );
};
