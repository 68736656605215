import type { ComponentType } from "@data-driven-forms/react-form-renderer/component-types";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import type { DataType } from "@data-driven-forms/react-form-renderer/data-types";
import dataTypes from "@data-driven-forms/react-form-renderer/data-types";
import { isDemoSite, isDevSite, isLocalSite } from "consts/client";
import { checkAgeEligibility } from "helpers/activity";
import { getAreAddonsWithActivityEqual } from "helpers/checkout";
import { getIsCurrencyZeroDecimal } from "helpers/currency";
import { formatDate } from "helpers/date";
import {
  getAllSessionTicketPrice,
  getSubscriptionTicketPrice
} from "helpers/ticket";
import { isEqual, orderBy } from "lodash";
import type { ClientDocument } from "models/Client";
import type { NextApiRequest } from "next";
import type {
  Activity,
  ActivityDate,
  AddOn,
  AddOnWithActivity,
  OverriddenSessionTimeDisplay,
  Ticket
} from "types/model/activity";
import { TicketType } from "types/model/activity";
import type {
  ActivityGroup,
  ActivityGroupFullListItem
} from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import { BookingStatus } from "types/model/booking";
import { AgreementUsage } from "types/model/booking-agreement";
import type {
  BookingItemByActivityGroup,
  SessionLineItem
} from "types/model/cart";
import type { Client } from "types/model/client";
import { Currency } from "types/model/client";
import { FieldType } from "types/model/field";
import type { LineItem } from "types/model/line-item";
import { LineItemType } from "types/model/line-item";
import type { PaymentMethodInstance } from "types/model/payment";
import { PaymentProviderName } from "types/model/payment";
import type { User } from "types/model/user";
import { UserSubscriptionStatus } from "types/model/user-subscription";

export function getBaseUrl(req: NextApiRequest): string {
  const host = req
    ? req.headers["x-forwarded-host"] || req.headers["host"]
    : window.location.host;
  const protocol = host?.includes(".local") ? "http://" : "https://";

  return `${protocol}${host}`;
}

export const getClientBaseUrl = (client: Client | ClientDocument): string => {
  if (client.customDomain && client.customDomainIsDefault) {
    return `https://${client.customDomain}/`;
  }

  let protocol = "https://";
  let extension = "app";

  if (isDemoSite) {
    extension = "online";
  } else if (isDevSite) {
    extension = "dev";
  } else if (isLocalSite) {
    protocol = "http://";
    extension = "local:4000";
  }

  return `${protocol}${client.subdomain}.pembee.${extension}/`;
};

export const getClientDomainName = (client: Client): string => {
  if (client.customDomain && client.customDomainIsDefault) {
    return client.customDomain;
  }

  let extension = "app";
  if (isDemoSite) {
    extension = "online";
  } else if (isDevSite) {
    extension = "dev";
  }
  return `${client.subdomain}.pembee.${extension}`;
};

export const getUserFullName = (user: Partial<User>) => {
  const firstNameFieldData = user.fieldData?.find(
    item => item.field?.internalKey === "user_firstName"
  );
  if (!firstNameFieldData) {
    console.error(`Can't find first name field data for user ${user._id}`);
  }
  const lastNameFieldData = user.fieldData?.find(
    item => item.field?.internalKey === "user_lastName"
  );
  if (!lastNameFieldData) {
    console.error(`Can't find last name field data for user ${user._id}`);
  }
  // Fallback to email if no first or last name
  if (!firstNameFieldData?.value && !lastNameFieldData?.value) {
    const emailFieldData = user.fieldData?.find(
      item => item.field?.internalKey === "user_email"
    );
    return emailFieldData?.value;
  }

  return `${firstNameFieldData?.value} ${lastNameFieldData?.value}`;
};

export const getUserFirstName = (user: Partial<User>): string => {
  const firstName = user.fieldData?.find(
    item => item.field?.internalKey === "user_firstName"
  );
  return firstName?.value;
};

export const getUserLastName = (user: Partial<User>): string => {
  const lastName = user.fieldData?.find(
    item => item.field?.internalKey === "user_lastName"
  );
  return lastName?.value;
};

export const getUserEmail = (user: Partial<User>): string => {
  const emailFieldData = user.fieldData?.find(
    item => item.field?.internalKey === "user_email"
  );
  if (!emailFieldData) {
    console.error(`Can't find email field data for user ${user._id}`);
  }
  return emailFieldData?.value;
};

export const getUserPhone = (user: Partial<User>): string => {
  const phoneFieldData = user.fieldData?.find(
    item => item.field?.internalKey === "user_phone"
  );
  if (!phoneFieldData) {
    console.error(`Can't find phone field data for user ${user._id}`);
  }
  return phoneFieldData?.value;
};

export const getAttendeeFullName = (attendee: Attendee): string => {
  const firstName = attendee.fieldData.find(
    item => item.field?.internalKey === "attendee_firstName"
  );
  const lastName = attendee.fieldData.find(
    item => item.field?.internalKey === "attendee_lastName"
  );
  return `${firstName?.value} ${lastName?.value}`;
};

export const getAttendeeFirstName = (attendee: Attendee): string => {
  const firstName = attendee.fieldData.find(
    item => item.field?.internalKey === "attendee_firstName"
  );
  return firstName?.value;
};

export const getAttendeeLastName = (attendee: Attendee): string => {
  const lastName = attendee.fieldData.find(
    item => item.field?.internalKey === "attendee_lastName"
  );
  return lastName?.value;
};

export const getAttendeeDob = (attendee: Attendee, client: Client): string => {
  const attendeeDob = attendee.fieldData.find(
    item => item.field?.internalKey === "attendee_dob"
  );
  return attendeeDob?.value
    ? formatDate(attendeeDob.value, client.dateFormat, client.timeZone)
    : "";
};

export function getActivityTitle(
  activityGroup?: ActivityGroup | ActivityGroupFullListItem
): string {
  if (!activityGroup) return "";

  const activityTitle = activityGroup.fieldData.find(
    item => item.field?.internalKey === "activity_title"
  );

  return activityTitle?.value;
}

export function getActivitySubtitle(
  activityGroup?: ActivityGroup | ActivityGroupFullListItem
): string {
  if (!activityGroup) return "";

  const activitySubtitle = activityGroup.fieldData.find(
    item => item.field?.internalKey === "activity_subtitle"
  );
  return activitySubtitle?.value;
}

export const getActivityVenueName = (
  activityGroup: ActivityGroup | ActivityGroupFullListItem
): string => {
  const venueFieldDataItem = activityGroup.fieldData.find(
    item => item.field?.internalKey === "activity_venue"
  );

  return venueFieldDataItem?.valueRefVenue?.name || "";
};

export const getActivityTitleAndVenueName = (
  activityGroup: ActivityGroup
): string => {
  const activityTitle = activityGroup.fieldData.find(
    item => item.field?.internalKey === "activity_title"
  );
  const venueFieldDataItem = activityGroup.fieldData.find(
    item => item.field?.internalKey === "activity_venue"
  );
  return `${activityTitle?.value}${
    venueFieldDataItem?.valueRefVenue?.name
      ? `, ${venueFieldDataItem?.valueRefVenue?.name}`
      : ""
  }`;
};

interface RenderActivityDateStringParams {
  activityDate: ActivityDate;
  dateOnly: boolean;
  timeOnly: boolean;
  timeZone: string;
  includeYear?: boolean;
  timeOverride?: OverriddenSessionTimeDisplay;
}
export const renderActivityDateString = ({
  activityDate,
  dateOnly,
  timeOnly,
  timeZone,
  includeYear = true,
  timeOverride
}: RenderActivityDateStringParams): string => {
  const format = includeYear ? "E d MMM yyyy" : "E d MMM";
  const startDate = formatDate(activityDate.start, format, timeZone);

  let startTime: string;
  let endTime: string;

  // This is is ugly but the best way to check all these values are defined
  const shouldOverrideTime =
    timeOverride?.start?.hours !== undefined &&
    timeOverride.start.hours !== null &&
    timeOverride.start.minutes !== undefined &&
    timeOverride.start.minutes !== null &&
    timeOverride.end?.hours !== undefined &&
    timeOverride.end.hours !== null &&
    timeOverride.end.minutes !== undefined &&
    timeOverride.end.minutes !== null;

  if (shouldOverrideTime) {
    // start time
    const startTimeAsTwelveHourFormat =
      timeOverride.start.hours > 12
        ? timeOverride.start.hours - 12
        : timeOverride.start.hours === 0
          ? 12
          : timeOverride.start.hours;
    const startTimePaddedMinutes = String(timeOverride.start.minutes).padStart(
      2,
      "0"
    );
    const startTimeAmPm = timeOverride.start.hours >= 12 ? "pm" : "am";

    // end time
    const endTimeAsTwelveHourFormat =
      timeOverride.end.hours > 12
        ? timeOverride.end.hours - 12
        : timeOverride.end.hours === 0
          ? 12
          : timeOverride.end.hours;
    const endTimePaddedMinutes = String(timeOverride.end.minutes).padStart(
      2,
      "0"
    );
    const endTimeAmPm = timeOverride.end.hours >= 12 ? "pm" : "am";

    startTime = `${startTimeAsTwelveHourFormat}.${startTimePaddedMinutes}${startTimeAmPm}`;
    endTime = `${endTimeAsTwelveHourFormat}.${endTimePaddedMinutes}${endTimeAmPm}`;
  } else {
    startTime = formatDate(activityDate.start, "h.mma", timeZone).toLowerCase();
    endTime = formatDate(activityDate.end, "h.mma", timeZone).toLowerCase();
  }

  if (dateOnly) {
    return startDate;
  } else if (timeOnly) {
    return `${startTime}${endTime ? ` - ${endTime}` : ""}`;
  } else {
    return `${startDate}, ${startTime}${endTime ? ` - ${endTime}` : ""}`;
  }
};

export const capitalize = (s: string): string => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getSymbolForCurrencyInput = (currency: Currency): string => {
  if (
    currency === Currency.AED ||
    currency === Currency.QAR ||
    currency === Currency.SAR ||
    currency === Currency.MYR ||
    currency === Currency.CHF
  ) {
    return "";
  }
  if (currency === Currency.GHS) {
    return "₵";
  }
  return getCurrencySymbol(currency);
};

export const getCurrencySymbol = (currency: Currency | string): string => {
  const dollarCurrencies = [
    Currency.AUD,
    Currency.NZD,
    Currency.SGD,
    Currency.USD,
    Currency.CAD,
    Currency.HKD,
    Currency.MXN,
    Currency.FJD
  ];

  if (dollarCurrencies.includes(currency as Currency)) {
    return "$";
  } else if (currency === Currency.EUR) {
    return "€";
  } else if (currency === Currency.GBP) {
    return "£";
  } else if (
    currency === Currency.NOK ||
    currency === Currency.SEK ||
    currency === Currency.DKK
  ) {
    return "kr";
  } else if (currency === Currency.AED) {
    return "AED";
  } else if (currency === Currency.THB) {
    return "฿";
  } else if (currency === Currency.QAR) {
    return "QR";
  } else if (currency === Currency.JPY) {
    return "¥";
  } else if (currency === Currency.SAR) {
    return "SAR";
  } else if (currency === Currency.GHS) {
    return "GH₵";
  } else if (currency === Currency.MYR) {
    return "RM";
  } else if (currency === Currency.CHF) {
    return "CHF";
  }

  // Currently falls back to dollar symbol for any other currency
  return "$";
};

export const formatCurrency = ({
  rawAmount,
  includeCurrencySymbol = true,
  includeCents = true,
  currency,
  showFree = false
}: {
  rawAmount?: number;
  includeCurrencySymbol?: boolean;
  includeCents?: boolean;
  currency: Currency | string;
  showFree?: boolean;
}): string => {
  if (rawAmount === undefined) return "";
  if (rawAmount === 0 && showFree) return "Free";

  const isZeroDecimalCurrency = getIsCurrencyZeroDecimal(currency as Currency);

  let amount: number | string;

  if (isZeroDecimalCurrency) {
    amount = rawAmount;
  } else if (!includeCents) {
    amount = Math.round(rawAmount / 100);
  } else {
    amount = (rawAmount / 100).toFixed(2);
  }

  return `${includeCurrencySymbol ? getCurrencySymbol(currency) : ""}${amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const formatNumber = (number: number): string => {
  return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const getBookingStatusReadableName = (bookingStatus: BookingStatus) => {
  switch (bookingStatus) {
    case BookingStatus.Completed:
      return "Completed";
    case BookingStatus.Pending:
      return "Pending";
    case BookingStatus.Processing:
      return "Processing";
    case BookingStatus.Cancelled:
      return "Cancelled";
    case BookingStatus.Abandoned:
      return "Abandoned";
    default:
      return "Unknown booking status";
  }
};

export const getBadgeColorFromBookingStatus = (
  bookingStatus: BookingStatus
): string => {
  switch (bookingStatus) {
    case BookingStatus.Completed:
      return "green";
    case BookingStatus.Pending:
    case BookingStatus.Processing:
      return "yellow";
    case BookingStatus.Cancelled:
      return "gray";
    case BookingStatus.Abandoned:
      return "red";
    default:
      return "gray";
  }
};

export const getBadgeColorFromUserSubscriptionStatus = (
  userSubscriptionStatus: UserSubscriptionStatus
): string => {
  switch (userSubscriptionStatus) {
    case UserSubscriptionStatus.Active:
      return "green";
    case UserSubscriptionStatus.NotStarted:
      return "yellow";
    case UserSubscriptionStatus.Cancelled:
    case UserSubscriptionStatus.Ended:
      return "gray";
    case UserSubscriptionStatus.PastDue:
      return "red";
    default:
      return "gray";
  }
};

export const getDdfDataType = (fieldType: FieldType): DataType => {
  switch (fieldType) {
    case FieldType.Text:
    case FieldType.Textarea:
    case FieldType.Email:
    case FieldType.SelectList:
    case FieldType.Venue:
    case FieldType.CheckboxMultiple:
    case FieldType.Date:
    case FieldType.Phone:
      return dataTypes.STRING;
    case FieldType.Currency:
    case FieldType.Number:
      return dataTypes.NUMBER;
    case FieldType.Integer:
      return dataTypes.INTEGER;
    case FieldType.CheckboxSingle:
      return dataTypes.BOOLEAN;
    default:
      throw new Error("Invalid field type");
  }
};

export const getFormComponentFromFieldType = (
  fieldType: FieldType
): ComponentType | string => {
  switch (fieldType) {
    case FieldType.Text:
    case FieldType.Email:
    case FieldType.Integer:
    case FieldType.Number:
      return componentTypes.TEXT_FIELD;
    case FieldType.Textarea:
      return componentTypes.TEXTAREA;
    case FieldType.Currency:
      return "currency";
    case FieldType.SelectList:
      return componentTypes.SELECT;
    case FieldType.CheckboxSingle:
      return componentTypes.CHECKBOX;
    case FieldType.CheckboxMultiple:
      return "checkbox-multiple";
    case FieldType.Date:
      return componentTypes.DATE_PICKER;
    case FieldType.Venue:
      return "venue";
    case FieldType.Phone:
      return "phone";
    default:
      throw new Error("Invalid field type");
  }
};

export const getInputType = (fieldType: FieldType): string => {
  switch (fieldType) {
    case FieldType.Text:
      return "text";
    case FieldType.Email:
      return "email";
    case FieldType.Number:
    case FieldType.Integer:
      return "number";
    default:
      return "text";
  }
};

export const renderTicketTypeFriendlyName = (
  ticketType: TicketType
): string => {
  switch (ticketType) {
    case TicketType.All:
      return "All sessions";
    case TicketType.Single:
      return "Single session";
    case TicketType.Subscription:
      return "Subscription";
    default:
      throw new Error("Invalid ticket type");
  }
};

export const getTotalFromActivityGroupItems = (
  activityGroupItems: BookingItemByActivityGroup[]
) => {
  const total = activityGroupItems.reduce((acc, item) => acc + item.total, 0);
  return total;
};

// THIS FORMATS 'SESSION LINE ITEMS' AS 'BookingItemByActivityGroup' (format used for normal cart)
export const formatSessionLineItemsByActivityGroup = (
  sessionLineItems: SessionLineItem[],
  client: Client
): BookingItemByActivityGroup[] => {
  const orderedSessionLineItems: SessionLineItem[] = orderBy(
    sessionLineItems,
    [
      "ticket.type",
      "activity.date.start",
      lineItem => lineItem?.children?.length
    ],
    ["asc", "asc", "asc"]
  );

  return orderedSessionLineItems
    .filter(lineItem => lineItem.type === LineItemType.Activity)
    .reduce(
      (
        accumulator: BookingItemByActivityGroup[],
        { activityGroup, activities, ticket: ticketItem, _id }
      ) => {
        const ticket = ticketItem as Ticket;
        const existingActivityGroup = accumulator.find(item =>
          item.activityGroup._id.equals(activityGroup._id)
        );

        const addOns: AddOnWithActivity[] = sessionLineItems
          .filter(
            lineItem =>
              lineItem.addOn &&
              lineItem.type === LineItemType.AddOn &&
              lineItem.parent === _id
          )
          .map(addOnLineItem => ({
            ...(
              addOnLineItem.addOn as AddOn & { toObject: () => AddOn }
            ).toObject(),
            ...([TicketType.All, TicketType.Subscription].includes(
              ticket?.type as TicketType
            ) && {
              activity: addOnLineItem.activity
            })
          }));

        const addOnsTotal = addOns.reduce(
          (acc, addOn) => acc + (addOn.price ?? 0),
          0
        );

        let total = 0;

        if (ticket?.type === TicketType.All) {
          total =
            getAllSessionTicketPrice(ticket, activityGroup, client) +
            addOnsTotal;
        } else if (ticket?.type === TicketType.Subscription) {
          total = getSubscriptionTicketPrice(ticket, activityGroup, client);
        } else if (ticket?.type === TicketType.Single) {
          total = ticket.price + addOnsTotal;
        }

        if (existingActivityGroup) {
          const existingTicketWithSameAddons =
            existingActivityGroup.ticketItems.find(item => {
              const areTicketAndActivitiesEqual =
                item.ticket._id.equals(ticket?._id) &&
                ([TicketType.All, TicketType.Subscription].includes(
                  item.ticket.type
                ) ||
                  (item.ticket.type === TicketType.Single &&
                    item.activities[0]._id.equals(activities?.[0]._id)));

              const areAddOnsEqual = getAreAddonsWithActivityEqual(
                item.addOns,
                addOns
              );

              return areTicketAndActivitiesEqual && areAddOnsEqual;
            });

          if (existingTicketWithSameAddons) {
            existingTicketWithSameAddons.lineItems.push({ _id });
            existingActivityGroup.total += total;
          } else {
            existingActivityGroup.ticketItems.push({
              activities: activities as Activity<string>[],
              ticket: {
                _id: ticket._id,
                name: ticket.name,
                type: ticket.type,
                timeDisplay: ticket?.timeDisplay,
                ...(ticket?.type === TicketType.Subscription && {
                  subscriptionPlan: ticket.subscriptionPlan
                })
              },
              lineItems: [{ _id }],
              addOns,
              itemPrice: total
            });
            existingActivityGroup.total = existingActivityGroup.total + total;
          }
        } else {
          accumulator.push({
            activityGroup,
            ticketItems: [
              {
                activities: activities as Activity<string>[],
                ticket: {
                  _id: ticket._id,
                  name: ticket.name,
                  type: ticket.type,
                  timeDisplay: ticket?.timeDisplay,
                  ...(ticket?.type === TicketType.Subscription && {
                    subscriptionPlan: ticket.subscriptionPlan
                  })
                },
                lineItems: [{ _id }],
                addOns,
                itemPrice: total
              }
            ],
            total
          });
        }
        return accumulator;
      },
      []
    );
};

export const getEarliestActivityDate = (
  activities: Activity<string>[] = []
): Date => {
  const ordered: Activity<string>[] = orderBy(
    activities,
    ["date.start"],
    ["asc"]
  );

  return ordered[0]?.date?.start;
};

export const getLatestActivityDate = (
  activities: Activity<string>[] = []
): Date => {
  const ordered: Activity<string>[] = orderBy(
    activities,
    ["date.start"],
    ["desc"]
  );

  return ordered[0]?.date?.start;
};

export const getEarliestAddonDateForAllSessionChildLineItems = (
  lineItems: LineItem[] = []
): Date => {
  const ordered: LineItem[] = orderBy(
    lineItems,
    ["lineItem.activity.date.start"],
    ["asc"]
  );
  return ordered[0]?.activity?.date?.start;
};

interface GetFormattedBookingLineItemsByActivityGroupData {
  bookingItemsByActivityGroup: BookingItemByActivityGroup[];
  areAnyAttendeesIneligible: boolean;
}

export const getFormattedBookingLineItemsByActivityGroup = (
  lineItems: LineItem[],
  groupAddons = true
): GetFormattedBookingLineItemsByActivityGroupData => {
  const orderedLineItems: LineItem[] = orderBy(
    lineItems.filter(lineItem => lineItem.type === LineItemType.Activity),
    [
      (lineItem: LineItem) => getEarliestActivityDate(lineItem.activities),
      "ticket.type",
      lineItem => lineItem?.children?.length,
      lineItem =>
        [TicketType.All, TicketType.Subscription].includes(
          lineItem.ticket?.type
        )
          ? getEarliestAddonDateForAllSessionChildLineItems(lineItem?.children)
          : 0,
      lineItem =>
        lineItem.ticket?.type === TicketType.Single
          ? lineItem?.children[0]?.addOn?.name
          : 0,
      "created"
    ],
    ["asc", "asc", "asc", "asc", "asc", "asc"]
  );

  let areAnyAttendeesIneligible = false;

  const bookingItemsByActivityGroup = orderedLineItems.reduce(
    (
      accumulator: BookingItemByActivityGroup[],
      {
        activityGroup,
        activities,
        ticket,
        _id,
        attendee,
        total,
        children,
        userSubscription,
        cancelled,
        waitlistEntry
      }
    ) => {
      const existingActivityGroup = accumulator.find(item =>
        item.activityGroup._id.equals(activityGroup._id)
      );

      // Check attendee age restrictions
      const {
        isAttendeeDobOutsideAgeRestriction,
        shouldBlockAttendeeIfOutsideAgeRestriction
      } = checkAgeEligibility(attendee, activityGroup, activities);

      if (
        isAttendeeDobOutsideAgeRestriction &&
        shouldBlockAttendeeIfOutsideAgeRestriction
      ) {
        areAnyAttendeesIneligible = true;
      }

      const addOns: AddOnWithActivity[] = children.map(lineItem => ({
        ...(lineItem?.addOn as AddOn & { toObject: () => AddOn }).toObject(),
        ...([TicketType.All, TicketType.Subscription].includes(ticket.type) && {
          activity: lineItem.activity
        }),
        lineItemId: lineItem._id,
        parentId: lineItem.parent
      }));

      const addOnsTotal = children.reduce(
        (acc, lineItem) => acc + (lineItem?.addOn?.price as number),
        0
      );

      const activityIds = activities.map(activity => activity._id).sort();

      if (existingActivityGroup) {
        const existingTicketWithSameAddons =
          existingActivityGroup.ticketItems.find(item => {
            const itemActivityIds = item.activities
              .map(activity => activity._id)
              .sort();
            return (
              item.ticket._id.equals(ticket._id) &&
              isEqual(activityIds, itemActivityIds) &&
              getAreAddonsWithActivityEqual(item.addOns, addOns)
            );
          });

        if (existingTicketWithSameAddons && groupAddons) {
          existingTicketWithSameAddons.lineItems.push({
            _id,
            attendee,
            total,
            cancelled,
            userSubscription,
            isAttendeeDobOutsideAgeRestriction,
            shouldBlockAttendeeIfOutsideAgeRestriction,
            ...(waitlistEntry && {
              waitlistEntryId: waitlistEntry._id
            })
          });

          if (!cancelled) {
            existingActivityGroup.total += total + addOnsTotal;
          }
        } else {
          existingActivityGroup.ticketItems.push({
            activities,
            ticket: {
              _id: ticket._id,
              name: ticket.name,
              type: ticket.type,
              timeDisplay: ticket.timeDisplay,
              ...(ticket.type === TicketType.Subscription && {
                subscriptionPlan: ticket.subscriptionPlan
              })
            },
            lineItems: [
              {
                _id,
                attendee,
                total,
                cancelled,
                userSubscription,
                isAttendeeDobOutsideAgeRestriction,
                shouldBlockAttendeeIfOutsideAgeRestriction,
                ...(waitlistEntry && {
                  waitlistEntryId: waitlistEntry._id
                })
              }
            ],
            addOns,
            itemPrice: total + addOnsTotal
          });
          if (!cancelled) {
            existingActivityGroup.total =
              existingActivityGroup.total + total + addOnsTotal;
          }
        }
      } else {
        accumulator.push({
          activityGroup,
          ticketItems: [
            {
              activities,
              ticket: {
                _id: ticket._id,
                name: ticket.name,
                type: ticket.type,
                timeDisplay: ticket.timeDisplay,
                ...(ticket.type === TicketType.Subscription && {
                  subscriptionPlan: ticket.subscriptionPlan
                })
              },
              lineItems: [
                {
                  _id,
                  attendee,
                  total,
                  cancelled,
                  userSubscription,
                  isAttendeeDobOutsideAgeRestriction,
                  shouldBlockAttendeeIfOutsideAgeRestriction,
                  ...(waitlistEntry && {
                    waitlistEntryId: waitlistEntry._id
                  })
                }
              ],
              addOns,
              itemPrice: total + addOnsTotal
            }
          ],
          total: !cancelled ? total + addOnsTotal : 0
        });
      }

      return accumulator;
    },
    []
  );

  return {
    areAnyAttendeesIneligible,
    bookingItemsByActivityGroup
  };
};

export const getAllowedPaymentMethodsForAdmin = (
  paymentMethods: PaymentMethodInstance[]
): PaymentMethodInstance[] => {
  const allowedPaymentMethodsForAdmin = paymentMethods.filter(
    paymentMethod =>
      paymentMethod.provider.internalName === PaymentProviderName.Offline ||
      ((isCcvPaymentMethod(paymentMethod) ||
        isTaxFreeChildcarePaymentMethod(paymentMethod)) &&
        paymentMethod.enabled)
  );

  return allowedPaymentMethodsForAdmin;
};

export const isStripePaymentMethod = (
  paymentMethod: PaymentMethodInstance
): boolean => {
  return paymentMethod?.provider?.internalName === PaymentProviderName.Stripe;
};

export const isCcvPaymentMethod = (
  paymentMethod: PaymentMethodInstance
): boolean => {
  return (
    paymentMethod?.provider?.internalName ===
    PaymentProviderName.ChildcareVoucher
  );
};

export const isTaxFreeChildcarePaymentMethod = (
  paymentMethod: PaymentMethodInstance
): boolean => {
  return (
    paymentMethod?.provider?.internalName ===
    PaymentProviderName.TaxFreeChildcare
  );
};

export const isOfflinePaymentMethod = (
  paymentMethod: PaymentMethodInstance
): boolean => {
  return paymentMethod?.provider?.internalName === PaymentProviderName.Offline;
};

export const poll = async (
  fn: () => Promise<unknown>,
  fnCondition: (...data: any[]) => boolean,
  ms: number
): Promise<unknown> => {
  let result = await fn();
  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
  }
  return result;
};

export const wait = (ms = 1000): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const getDoesArrayHaveDuplications = (array: unknown[]) => {
  return new Set(array).size !== array.length;
};

export const getDoesArrayHaveMultipleUniqueValues = (array: unknown[]) => {
  return new Set(array).size > 1;
};

export const getBookingAgreementUsageReadableName = (
  usage: AgreementUsage
): string => {
  switch (usage) {
    case AgreementUsage.Booking:
      return "Booking agreement";
    case AgreementUsage.Registration:
      return "User registration agreement";
    default:
      return "";
  }
};
