import { getLineItemTotalWithAddOns } from "helpers/booking";
import { isEqual } from "lodash";
import type { Activity, AddOnWithActivity } from "types/model/activity";
import { TicketType } from "types/model/activity";
import type { Attendee } from "types/model/attendee";
import type { AddOnBooking, Booking } from "types/model/booking";
import type {
  AddOnIdsByActivity,
  CartItemByTicket,
  CartLineItem,
  UserBooking
} from "types/model/cart";
import type { LineItem } from "types/model/line-item";
import { LineItemType } from "types/model/line-item";

interface GetAttendeesForSelectionData {
  cartLineItem: CartLineItem;
  activities: Activity<string>[];
  ticketType: TicketType;
  attendees: Attendee[];
  itemsByTicket: CartItemByTicket[];
}

interface BookingPaymentAmountForAttendee {
  attendeeId: string;
  amount: number;
}

export const getAttendeesForSelection = ({
  cartLineItem,
  activities,
  ticketType,
  attendees,
  itemsByTicket
}: GetAttendeesForSelectionData): Attendee[] => {
  if (!attendees) return [];

  const attendeesAlreadySelected: string[] = itemsByTicket.reduce(
    (acc, item) => {
      if (
        item.ticket.type === TicketType.All ||
        ticketType === TicketType.All ||
        (item.ticket.type === TicketType.Single &&
          ticketType === TicketType.Single &&
          item.activities[0]._id === activities[0]._id)
      ) {
        const attendeeIds = item.lineItems.reduce((acc, cartLineItem) => {
          if (cartLineItem.attendee) {
            acc.push(cartLineItem.attendee._id);
          }

          return acc;
        }, [] as string[]);

        acc = [...acc, ...attendeeIds];
      }
      return acc;
    },
    [] as string[]
  );

  const filteredAttendees = attendees.filter(attendee => {
    if (attendee._id === cartLineItem.attendee?._id) return true; // already selected

    return !attendeesAlreadySelected.includes(attendee._id);
  });

  return filteredAttendees;
};

export const getAttendeesForPaymentsFromUserBooking = (
  booking: UserBooking
): Attendee[] => {
  const attendees: Attendee[] = booking.activityGroupItems.reduce(
    (activityGroupItemsAcc, item) => {
      const attendeesForItem = item.ticketItems.reduce(
        (ticketItemsAcc, ticketItem) => {
          const attendeesForTicketItem = (ticketItem.lineItems || [])
            .filter(lineItem => (lineItem.total || 0) > 0)
            .map(lineItem => lineItem.attendee as Attendee);

          return ticketItemsAcc.concat(attendeesForTicketItem);
        },
        [] as Attendee[]
      );

      return activityGroupItemsAcc.concat(attendeesForItem);
    },
    [] as Attendee[]
  );

  const uniqueAttendees: Attendee[] = attendees.reduce(
    (attendeesAcc, attendee) => {
      const alreadyExists = attendeesAcc.some(
        item => item._id === attendee._id
      );

      if (!alreadyExists) {
        return attendeesAcc.concat([attendee]);
      } else {
        return attendeesAcc;
      }
    },
    [] as Attendee[]
  );

  return uniqueAttendees;
};

export const getBookingPaymentAmountsForEachAttendee = (
  booking: Booking,
  total: number
): BookingPaymentAmountForAttendee[] => {
  const bookingPaymentAmountsForEachAttendee = booking.lineItems
    .filter(
      lineItem => lineItem.type === LineItemType.Activity && lineItem.total > 0
    )
    .reduce((acc, lineItem) => {
      const existingItemIndex = acc.findIndex(
        item => lineItem.attendee._id === item.attendeeId
      );
      if (existingItemIndex > -1) {
        acc[existingItemIndex].amount =
          acc[existingItemIndex].amount + getLineItemTotalWithAddOns(lineItem);
      } else {
        acc.push({
          attendeeId: lineItem.attendee._id,
          amount: getLineItemTotalWithAddOns(lineItem)
        });
      }
      return acc;
    }, [] as BookingPaymentAmountForAttendee[]);

  const totalAssigned = bookingPaymentAmountsForEachAttendee.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const bookingPaymentAmountsForEachBasedOnTotal =
    bookingPaymentAmountsForEachAttendee.map(item => {
      const amount = Math.round((item.amount / totalAssigned) * total);
      return {
        ...item,
        amount: amount
      };
    });

  return bookingPaymentAmountsForEachBasedOnTotal;
};

export const getAddOnPaymentAmountsForEachAttendee = (
  addOnBooking: AddOnBooking<string, LineItem>,
  total: number
): BookingPaymentAmountForAttendee[] => {
  const addOnPaymentAmountsForEachAttendee = addOnBooking.addOnItems
    .filter(addOnItem => addOnItem.addOn.price > 0)
    .reduce((acc: BookingPaymentAmountForAttendee[], addOnItem) => {
      const existingItemIndex = acc.findIndex(
        item => addOnItem.parent.attendee._id === item.attendeeId
      );
      if (existingItemIndex > -1) {
        acc[existingItemIndex].amount =
          acc[existingItemIndex].amount + addOnItem.addOn.price;
      } else {
        acc.push({
          attendeeId: addOnItem.parent.attendee._id,
          amount: addOnItem.addOn.price
        });
      }
      return acc;
    }, []);

  const totalAssigned = addOnPaymentAmountsForEachAttendee.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const addOnPaymentAmountsForEachBasedOnTotal =
    addOnPaymentAmountsForEachAttendee.map(item => {
      return {
        ...item,
        amount: (item.amount / totalAssigned) * total
      };
    });

  return addOnPaymentAmountsForEachBasedOnTotal;
};

export const getAddOnIdsByActivity = (
  addOns: AddOnWithActivity[]
): AddOnIdsByActivity[] => {
  const addOnIdsByActivity = addOns.reduce((acc, addOn) => {
    const existingActivityItem = acc.find(
      item => item.activityId === addOn.activity?._id
    );

    if (existingActivityItem) {
      existingActivityItem.addOnIds.push(addOn._id);
    } else {
      acc.push({
        activityId: addOn.activity?._id,
        addOnIds: [addOn._id]
      });
    }

    return acc;
  }, [] as AddOnIdsByActivity[]);

  return addOnIdsByActivity;
};

export const getAreAddonsWithActivityEqual = (
  addons1: AddOnWithActivity[],
  addons2: AddOnWithActivity[]
): boolean => {
  const activityIdAddOnIdCompounds1 = addons1
    .map(addOn => `${addOn.activity?._id}_${addOn._id}`)
    .sort();

  const activityIdAddOnIdCompounds2 = addons2
    .map(addOn => `${addOn.activity?._id}_${addOn._id}`)
    .sort();

  return isEqual(activityIdAddOnIdCompounds1, activityIdAddOnIdCompounds2);
};
