import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ButtonHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "utils/cn";

// Based on https://tailwindui.com/components/application-ui/elements/buttons
const buttonVariants = cva(
  "rounded font-medium cursor-pointer transition ring-0",
  {
    variants: {
      variant: {
        primary:
          "bg-indigo-600 text-white hover:bg-indigo-500 disabled:pointer-events-none disabled:opacity-50 border border-indigo-600",
        secondary:
          "text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
        white: "border-gray-300 bg-white text-gray-700 hover:bg-gray-50",
        soft: "bg-indigo-50 text-indigo-600 hover:bg-indigo-100",
        destructive:
          "bg-red-600 text-white hover:bg-red-500 border border-red-600",
        calendar:
          "border border-input bg-background hover:brightness-[97%] hover:text-accent-foreground outline-0 shadow-none",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        unstyled: ""
      },
      size: {
        xs: "px-2 py-1 text-xs",
        sm: "px-2 py-1 text-sm",
        md: "px-2.5 py-1.5 text-sm rounded-md",
        lg: "px-3 py-2 text-sm rounded-md",
        xl: "px-3.5 py-2.5 text-sm rounded-md",
        icon: "h-10 w-10",
        unstyled: ""
      }
    },
    defaultVariants: {
      variant: "primary",
      size: "lg"
    }
  }
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
