import type { TimeZone } from "@vvo/tzdb";
import {
  addDays,
  addMonths,
  differenceInMonths,
  differenceInYears,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  format,
  formatDuration,
  isAfter,
  isSameDay,
  isSameMonth,
  isValid,
  max,
  nextMonday,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import type { DateRange } from "react-day-picker";
import type { DateRangeStringifiedValues } from "types/date";
import { DateDuration } from "types/date";
import type { SelectOption } from "types/form";
import type { OverriddenSessionTimeDisplay } from "types/model/activity";
import type { Attendee } from "types/model/attendee";
import type { Client } from "types/model/client";

export const getDurationFromFrequencyInput = (
  frequency: string
): DateDuration => {
  switch (frequency) {
    case "daily":
      return DateDuration.Days;
    case "weekly":
      return DateDuration.Weeks;
    case "monthly":
      return DateDuration.Months;
    default:
      throw new Error("Invalid frequency value");
  }
};

export const formatDate = (
  date: string | number | Date,
  formatSequence: string,
  timeZone: string
): string => {
  if (!date) return "";

  const zonedTime = utcToZonedTime(date, timeZone);

  return format(zonedTime, formatSequence);
};

export const getIsSameDay = (
  date1: string | number | Date,
  date2: string | number | Date,
  timeZone: string
): boolean => {
  const zonedTime1 = utcToZonedTime(date1, timeZone);
  const zonedTime2 = utcToZonedTime(date2, timeZone);

  const isSame = isSameDay(zonedTime1, zonedTime2);

  return isSame;
};

interface GetDateFromInputData {
  dateString: string;
  startTime: string;
  endTime: string;
  timeZone: string;
}

export const getStartEndDatesFromInput = ({
  dateString,
  startTime,
  endTime,
  timeZone
}: GetDateFromInputData): { start: Date; end: Date } => {
  const startAndEndDates = {
    start: zonedTimeToUtc(new Date(`${dateString}T${startTime}`), timeZone),
    end: zonedTimeToUtc(new Date(`${dateString}T${endTime}`), timeZone)
  };

  return startAndEndDates;
};

interface GetDateFromDateAndTimeInputsData {
  dateString: string;
  time: string;
  timeZone: string;
}

export const getDateFromDateAndTimeInputs = ({
  dateString,
  time,
  timeZone
}: GetDateFromDateAndTimeInputsData): Date => {
  const date = new Date(`${dateString}T${time}`);

  if (!isValid(date)) return null as unknown as Date;

  return zonedTimeToUtc(date, timeZone);
};

export const isAfterStartOfDay = (date: Date, timeZone: string): boolean => {
  const timeZoneStartOfDay = getStartOfDay(timeZone);

  return isAfter(new Date(date), timeZoneStartOfDay);
};

export const getStartOfDay = (timeZone: string): Date => {
  const date = new Date();

  const zonedTime = utcToZonedTime(date, timeZone);
  const dateStartOfDay = startOfDay(zonedTime);

  const dateStartOfDayUtc = zonedTimeToUtc(dateStartOfDay, timeZone);

  return dateStartOfDayUtc;
};

export const getDateStartOfDay = (date: Date, timeZone: string): Date => {
  const dateStartOfDay = startOfDay(new Date(date));

  const dateStartOfDayUtc = zonedTimeToUtc(dateStartOfDay, timeZone);

  return dateStartOfDayUtc;
};

export const getDateEndOfDay = (date: Date, timeZone: string): Date => {
  const dateEndOfDay = endOfDay(new Date(date));

  const dateEndOfDayUtc = zonedTimeToUtc(dateEndOfDay, timeZone);

  return dateEndOfDayUtc;
};

export const getStartOfMonth = (timeZone: string): Date => {
  const date = new Date();

  const zonedTime = utcToZonedTime(date, timeZone);
  const dateStartOfMonth = startOfMonth(zonedTime);

  const dateStartOfMonthUtc = zonedTimeToUtc(dateStartOfMonth, timeZone);

  return dateStartOfMonthUtc;
};

export const getEndOfMonth = (timeZone: string): Date => {
  const date = new Date();

  const zonedTime = utcToZonedTime(date, timeZone);
  const dateEndOfMonth = endOfMonth(zonedTime);

  const dateEndOfMonthUtc = zonedTimeToUtc(dateEndOfMonth, timeZone);

  return dateEndOfMonthUtc;
};

export const getStartOfPreviousMonth = (timeZone: string): Date => {
  const date = new Date();
  const previousMonth = subMonths(date, 1);

  const zonedTime = utcToZonedTime(previousMonth, timeZone);
  const dateStartOfMonth = startOfMonth(zonedTime);

  const dateStartOfMonthUtc = zonedTimeToUtc(dateStartOfMonth, timeZone);

  return dateStartOfMonthUtc;
};

export const getStartOfNextMonth = (timeZone: string): Date => {
  const date = new Date();
  const previousMonth = addMonths(date, 1);

  const zonedTime = utcToZonedTime(previousMonth, timeZone);
  const dateStartOfMonth = startOfMonth(zonedTime);

  const dateStartOfMonthUtc = zonedTimeToUtc(dateStartOfMonth, timeZone);

  return dateStartOfMonthUtc;
};

/**
 * Returns the last day of the current month in the specified timezone.
 */
export const getLastDayOfCurrentMonth = (timeZone: string): Date => {
  const currentMonth = new Date();
  const lastDayOfCurrentMonth = endOfMonth(currentMonth);

  const zonedTime = utcToZonedTime(lastDayOfCurrentMonth, timeZone);
  const dateEndOfMonth = endOfMonth(zonedTime);

  const dateEndOfMonthUtc = zonedTimeToUtc(dateEndOfMonth, timeZone);

  return dateEndOfMonthUtc;
};

// temp!
export const getStartOf2MonthsAgo = (timeZone: string): Date => {
  const date = new Date();
  const previousMonth = subMonths(date, 2);

  const zonedTime = utcToZonedTime(previousMonth, timeZone);
  const dateStartOfMonth = startOfMonth(zonedTime);

  const dateStartOfMonthUtc = zonedTimeToUtc(dateStartOfMonth, timeZone);

  return dateStartOfMonthUtc;
};

export const getEndOfPreviousMonth = (timeZone: string): Date => {
  const date = new Date();
  const previousMonth = subMonths(date, 1);

  const zonedTime = utcToZonedTime(previousMonth, timeZone);
  const dateEndOfMonth = endOfMonth(zonedTime);

  const dateEndOfMonthUtc = zonedTimeToUtc(dateEndOfMonth, timeZone);

  return dateEndOfMonthUtc;
};

export const getDateStartOfMonth = (date: Date, timeZone: string): Date => {
  const dateStartOfMonth = startOfMonth(new Date(date));

  const dateStartOfMonthUtc = zonedTimeToUtc(dateStartOfMonth, timeZone);

  return dateStartOfMonthUtc;
};

export const getDateEndOfMonth = (date: Date, timeZone: string): Date => {
  const dateEndOfMonth = endOfMonth(new Date(date));

  const dateEndOfMonthUtc = zonedTimeToUtc(dateEndOfMonth, timeZone);

  return dateEndOfMonthUtc;
};

// https://github.com/marnusw/date-fns-tz/issues/67#issuecomment-927900990
export const getStartOfDayClientSide = (
  input: Date,
  timeZone: string
): Date => {
  // UTC: 09-27 03:00
  // Browser (Germany/Berlin): 09-27 05:00
  // Target (America/New_York): 09-26 23:00
  // const input = new Date();

  // UTC: 09-27 21:00
  // Browser (Germany/Berlin): 09-26 23:00
  const inputZoned = utcToZonedTime(input, timeZone);

  // UTC: 09-25 22:00
  // Browser (Germany/Berlin): 09-26 00:00
  const dayStartZoned = startOfDay(inputZoned);

  // UTC: 09-26 04:00
  // Browser (Germany/Berlin): 09-26 06:00
  // Target (America/New_York): 09-26 00:00
  const dayStart = zonedTimeToUtc(dayStartZoned, timeZone);

  return dayStart;
};

export const getEndOfDayClientSide = (input: Date, timeZone: string): Date => {
  const inputZoned = utcToZonedTime(input, timeZone);
  const dayEndZoned = endOfDay(inputZoned);
  const dayStart = zonedTimeToUtc(dayEndZoned, timeZone);

  return dayStart;
};

export const getStartOfWeekClientSide = (
  input: Date,
  timeZone: string
): Date => {
  const inputZoned = utcToZonedTime(input, timeZone);
  const dayStartZoned = startOfWeek(inputZoned, { weekStartsOn: 1 });
  const dayStart = zonedTimeToUtc(dayStartZoned, timeZone);

  return dayStart;
};

export const getStartOfMonthClientSide = (
  input: Date,
  timeZone: string
): Date => {
  const inputZoned = utcToZonedTime(input, timeZone);
  const monthStartZoned = startOfMonth(inputZoned);
  const monthStart = zonedTimeToUtc(monthStartZoned, timeZone);

  return monthStart;
};

export const getDateRangeValueAsStringifiedValues = (
  dateRange: DateRange
): DateRangeStringifiedValues => {
  let fromDateFormatted: string | undefined,
    toDateFormatted: string | undefined;

  if (dateRange?.from) {
    const offset = dateRange.from.getTimezoneOffset();
    const fromDateAdjustedForOffset = new Date(
      dateRange.from.getTime() - offset * 60 * 1000
    );
    if (isValid(fromDateAdjustedForOffset)) {
      fromDateFormatted = fromDateAdjustedForOffset.toISOString().split("T")[0];
    }
  }

  if (dateRange?.to) {
    const offset = dateRange.to.getTimezoneOffset();
    const toDateAdjustedForOffset = new Date(
      dateRange.to.getTime() - offset * 60 * 1000
    );
    if (isValid(toDateAdjustedForOffset)) {
      toDateFormatted = toDateAdjustedForOffset.toISOString().split("T")[0];
    }
  }

  return {
    from: fromDateFormatted ?? undefined,
    to: fromDateFormatted && toDateFormatted ? toDateFormatted : undefined
  };
};

export const formatTime = (
  hours: number,
  minutes: number,
  formatSequence = "h.mma"
): string => {
  const date = set(new Date(), { hours, minutes });

  return format(date, formatSequence);
};

export const formatDateStartEnd = (
  startDate: Date,
  endDate: Date,
  timeZone: string
): string => {
  const startDateFormat = isSameMonth(startDate, endDate) ? "d" : "d LLL";
  const endDateFormat = "d LLL";

  const startDateFormatted = formatDate(startDate, startDateFormat, timeZone);
  const endDateFormatted = formatDate(endDate, endDateFormat, timeZone);

  return `${startDateFormatted} - ${endDateFormatted}`;
};

export const getTimeZoneDisplayFormat = (timeZone: TimeZone): string => {
  if (!timeZone) return "";
  return `(GMT${timeZone.rawFormat.slice(0, 6)}) - ${timeZone.mainCities.join(
    ", "
  )}`;
};

export const formatMinutesAsDuration = (durationAsMinutes: number): string => {
  // Convert minutes to milliseconds
  const milliseconds = durationAsMinutes * 60 * 1000;

  // Define duration object
  const duration = {
    milliseconds: milliseconds % 1000,
    seconds: Math.floor((milliseconds / 1000) % 60),
    minutes: Math.floor((milliseconds / (1000 * 60)) % 60),
    hours: Math.floor((milliseconds / (1000 * 60 * 60)) % 24),
    days: Math.floor(milliseconds / (1000 * 60 * 60 * 24))
  };

  // Format the duration using the date-fns library
  const formattedDuration = formatDuration(duration);

  return formattedDuration;
};

export const formatMinutesAsDays = (durationAsMinutes: number): string => {
  const days = Math.floor(durationAsMinutes / 1440);
  return formatDuration(
    {
      days
    },
    { delimiter: ", " }
  );
};

interface GetInitialDateRangeFilterValuesData {
  startDay: number;
  endDay: number;
  client: Client;
}

export const getInitialDateRangeFilterValues = ({
  startDay,
  endDay,
  client
}: GetInitialDateRangeFilterValuesData): DateRangeStringifiedValues => {
  const start =
    startDay > 0
      ? addDays(new Date(), startDay)
      : subDays(new Date(), Math.abs(startDay));

  const end =
    endDay > 0
      ? addDays(new Date(), endDay)
      : subDays(new Date(), Math.abs(endDay));

  const dateRangeFromDefault = getStartOfDayClientSide(start, client.timeZone);

  const dateRangeToDefault = getEndOfDayClientSide(end, client.timeZone);

  const initialDateRange = {
    from: formatDate(dateRangeFromDefault, "yyyy-MM-dd", client.timeZone),
    to: formatDate(dateRangeToDefault, "yyyy-MM-dd", client.timeZone)
  };

  return initialDateRange;
};

interface GetBookingFiltersInitialDateRangeFilterValuesData {
  startMonthsAgo: number;
  client: Client;
}

export const getBookingFiltersInitialDateRangeFilterValues = ({
  startMonthsAgo,
  client
}: GetBookingFiltersInitialDateRangeFilterValuesData): DateRangeStringifiedValues => {
  const now = new Date();
  const monthsAgoValue = addDays(subMonths(new Date(), startMonthsAgo), 1);

  const dateRangeFromDefault = getStartOfDayClientSide(
    monthsAgoValue,
    client.timeZone
  );

  const dateRangeToDefault = getEndOfDayClientSide(now, client.timeZone);

  const initialDateRange = {
    from: formatDate(dateRangeFromDefault, "yyyy-MM-dd", client.timeZone),
    to: formatDate(dateRangeToDefault, "yyyy-MM-dd", client.timeZone)
  };

  return initialDateRange;
};

export const getDateRangeInputText = (
  dateRange: DateRangeStringifiedValues,
  client: Client
) => {
  // Date range contains stringified values of from and to dates
  // in the format 'yyyy-MM-dd'. These get sent to the server
  // so any timezone conversion is done there. Here we just
  // format the dates for display (so no timezone conversion is needed)
  let inputText = "";

  if (dateRange?.from) {
    const from = `${format(new Date(dateRange?.from), client.dateFormat)}`;
    inputText = from;

    if (dateRange?.to) {
      const to = `${format(new Date(dateRange?.to), client.dateFormat)}`;
      inputText = `${from} - ${to}`;
    }
  }

  return inputText;
};

export const getMonthlyOptionsForClient = (client: Client): SelectOption[] => {
  const date23MonthsAgo = subMonths(new Date(), 23);

  const start = max([new Date(client.created), date23MonthsAgo]);
  const end = new Date();

  const months = eachMonthOfInterval({
    start: start,
    end: end
  });

  const options: SelectOption[] = months.map(month => {
    const startOfMonth = getDateStartOfMonth(month, client.timeZone);
    return {
      value: formatDate(startOfMonth, "yyyy-MM", client.timeZone),
      label: formatDate(startOfMonth, "MMMM yyyy", client.timeZone)
    };
  });

  return options;
};

export const getAttendeeAgeOnActivitySessionDate = (
  attendee: Attendee,
  activitySessionDate: Date,
  client: Client
): string => {
  const attendeeDobFieldItem = attendee.fieldData.find(
    item => item.field?.internalKey === "attendee_dob"
  );
  if (!attendeeDobFieldItem) return "";

  const activitySessionDateStartOfDay = getDateStartOfDay(
    activitySessionDate,
    client.timeZone
  );

  const dobDate = new Date(attendeeDobFieldItem.value);

  let age = "";

  const diffInYears = differenceInYears(activitySessionDateStartOfDay, dobDate);

  if (diffInYears >= 1) {
    age = `${diffInYears}`;
  } else if (diffInYears === 0) {
    const diffInMonths = differenceInMonths(
      activitySessionDateStartOfDay,
      dobDate
    );
    age = `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"}`;
  }

  return age;
};

// convert 0 to 00, 1 to 01, etc.
export const padNumber = (min: number) => {
  return String(min).padStart(2, "0");
};

export const getOverriddenTimeAsReadableString = (
  timeOverride: OverriddenSessionTimeDisplay
) => {
  // start time
  const startTimeAsTwelveHourFormat =
    timeOverride.start.hours > 12
      ? timeOverride.start.hours - 12
      : timeOverride.start.hours === 0
        ? 12
        : timeOverride.start.hours;
  const startTimePaddedMinutes = String(timeOverride.start.minutes).padStart(
    2,
    "0"
  );
  const startTimeAmPm = timeOverride.start.hours >= 12 ? "pm" : "am";

  // end time
  const endTimeAsTwelveHourFormat =
    timeOverride.end.hours > 12
      ? timeOverride.end.hours - 12
      : timeOverride.end.hours === 0
        ? 12
        : timeOverride.end.hours;
  const endTimePaddedMinutes = String(timeOverride.end.minutes).padStart(
    2,
    "0"
  );
  const endTimeAmPm = timeOverride.end.hours >= 12 ? "pm" : "am";

  const startTime = `${startTimeAsTwelveHourFormat}.${startTimePaddedMinutes}${startTimeAmPm}`;
  const endTime = `${endTimeAsTwelveHourFormat}.${endTimePaddedMinutes}${endTimeAmPm}`;

  return `${startTime}${endTime ? ` - ${endTime}` : ""}`;
};

export const getNextMonday = (timeZone: string): Date => {
  const currentDate = new Date();
  const nextMondayDate = nextMonday(currentDate);
  const zonedDate = utcToZonedTime(nextMondayDate, timeZone);

  return zonedDate;
};
