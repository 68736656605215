import type { Day } from "date-fns";
import { add, compareAsc, getDay, isAfter, isBefore, sub } from "date-fns";
import { getIsActivitySessionAvailableToPurchase } from "features/activities/utils/getIsActivitySessionAvailableToPurchase";
import {
  formatDate,
  getDurationFromFrequencyInput,
  getStartOfNextMonth,
  isAfterStartOfDay
} from "helpers/date";
import {
  getEarliestActivityDate,
  getLatestActivityDate,
  renderActivityDateString
} from "helpers/helpers";
import { getCanAddOnBeUsedForActivity } from "helpers/ticket";
import type { Hash } from "types/general";
import type {
  Activity,
  ActivityDateInstance,
  AddOnWithActivity,
  GetActivitiesListingResponse,
  GetActivitySessionsListingResponse,
  Ticket
} from "types/model/activity";
import { TicketType } from "types/model/activity";
import type {
  ActivityGroup,
  ActivityGroupSalesData,
  ActivityGroupWithPastActivityIds,
  AgeRestrictions,
  AgeRestrictionsAgeValue
} from "types/model/activity-group";
import {
  AgeRestrictionCalculationCriteria,
  AgeRestrictionHandling,
  RepeatEndOption,
  RepeatOption
} from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { BookingItemByActivityGroup } from "types/model/cart";
import type { Client } from "types/model/client";
import type { AdminActivityGroupAccessFiltersFieldData } from "types/model/field-data";
import { AccessFilterDataType } from "types/model/field-data";

interface GetActivityDateInstancesWithRepeatsData {
  start: Date;
  end: Date;
  placeLimit?: number;
  repeatOption: RepeatOption;
  repeatDays?: Day[];
  repeatEndOption: RepeatEndOption;
  repeatOccurrences?: number;
  repeatEndDate?: Date;
}

export const getActivityDateInstancesWithRepeats = ({
  start,
  end,
  placeLimit,
  repeatOption,
  repeatDays = [0, 1, 2, 3, 4, 5, 6],
  repeatEndOption,
  repeatOccurrences,
  repeatEndDate
}: GetActivityDateInstancesWithRepeatsData): ActivityDateInstance[] => {
  const dates: ActivityDateInstance[] = [];

  if (repeatEndOption === RepeatEndOption.Occurrences) {
    const repeatsToGenerate = repeatOccurrences || 1;

    let index = 0;

    while (dates.length < repeatsToGenerate) {
      const startDate = add(start, {
        [getDurationFromFrequencyInput(repeatOption)]: index
      });
      const dayOfTheWeek = getDay(startDate);

      if (
        repeatOption === RepeatOption.Daily &&
        !repeatDays.includes(dayOfTheWeek)
      ) {
        // Skip this date if it's not one of the days of the week we want to repeat on
        index++;
        continue;
      }

      const nextDateInstance = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        enabled: true,
        placeLimit,
        date: {
          start: startDate,
          end: add(end, {
            [getDurationFromFrequencyInput(repeatOption)]: index
          })
        }
      };
      dates.push(nextDateInstance);

      index++;
    }
  } else if (repeatEndOption === RepeatEndOption.Date && repeatEndDate) {
    let nextDateInstanceStart = start;
    let index = 0;

    while (isBefore(nextDateInstanceStart, repeatEndDate)) {
      const startDate = add(start, {
        [getDurationFromFrequencyInput(repeatOption)]: index
      });
      const dayOfTheWeek = getDay(startDate);

      if (
        repeatOption === RepeatOption.Daily &&
        !repeatDays.includes(dayOfTheWeek)
      ) {
        // Skip this date if it's not one of the days of the week we want to repeat on
        nextDateInstanceStart = add(start, {
          [getDurationFromFrequencyInput(repeatOption)]: index + 1
        });
        index++;
        continue;
      }

      const dateInstanceToAdd = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        enabled: true,
        placeLimit,
        date: {
          start: startDate,
          end: add(end, {
            [getDurationFromFrequencyInput(repeatOption)]: index
          })
        }
      };
      dates.push(dateInstanceToAdd);

      nextDateInstanceStart = add(start, {
        [getDurationFromFrequencyInput(repeatOption)]: index + 1
      });
      index++;
    }
  }

  return dates;
};

export const getActivityGroupStartEnd = (
  activityGroup: ActivityGroup,
  client: Client
): string => {
  return activityGroup.activities
    .sort((a, b) => compareAsc(new Date(a.date.start), new Date(b.date.start))) // TODO: might be able to skip this if we ensure back end always returns activities sorted in date order
    .reduce((accumulator, current, index) => {
      if (index === 0) {
        accumulator += formatDate(
          current.date.start,
          client.dateFormat,
          client.timeZone
        );
      } else if (
        index + 1 === activityGroup.activities.length &&
        activityGroup.activities.length === 2
      ) {
        accumulator += `, ${formatDate(
          current.date.end,
          client.dateFormat,
          client.timeZone
        )}`;
      } else if (
        index + 1 === activityGroup.activities.length &&
        activityGroup.activities.length > 2
      ) {
        accumulator += ` - ${formatDate(
          current.date.end,
          client.dateFormat,
          client.timeZone
        )}`;
      }
      return accumulator;
    }, "");
};

export const getIsActivitySessionEnabledButPast = (
  activity: Activity<string>,
  activityGroup: ActivityGroup,
  client: Client
) => {
  const isActivitySessionEnabledButPast =
    activity.enabled &&
    (!isAfterStartOfDay(activity.date.start, client.timeZone) ||
      getHasClosingTimeForActivitySessionPassed(activity, activityGroup));

  return isActivitySessionEnabledButPast;
};

export const getHasActivityGroupPast = (
  client: Client,
  activityGroup: ActivityGroup
): boolean => {
  const hasActivitySessionsAfterStartOfToday = activityGroup.activities.some(
    activity =>
      getIsActivitySessionAvailableToPurchase(activity, activityGroup, client)
  );

  return !hasActivitySessionsAfterStartOfToday;
};

export const getDoesActivityGroupHaveAllSessionTicket = (
  activityGroup: ActivityGroup
): boolean => {
  const hasAllSessionTicket = (activityGroup.tickets || []).some(
    ticket => ticket.type === TicketType.All
  );

  return hasAllSessionTicket;
};

export const getActivityGroupDatesDescription = (
  client: Client,
  activityGroup: ActivityGroupWithPastActivityIds
): string => {
  let datesDescription = "";

  const hasAllSessionTicket =
    getDoesActivityGroupHaveAllSessionTicket(activityGroup);

  const activitiesPastIds = activityGroup.activitiesPastIds;

  const activities = hasAllSessionTicket
    ? activityGroup.activities
    : activityGroup.activities.filter(
        activity => !activitiesPastIds.includes(activity._id)
      );

  if (activities.length > 1) {
    datesDescription = `${
      activities.length
    } sessions from ${renderActivityDateString({
      activityDate: activities[0].date,
      dateOnly: true,
      timeOnly: false,
      timeZone: client.timeZone
    })}`;
  } else if (activities.length === 1) {
    datesDescription = renderActivityDateString({
      activityDate: activities[0].date,
      dateOnly: false,
      timeOnly: false,
      timeZone: client.timeZone
    });
  } else {
    datesDescription = "There are currently no dates to display.";
  }

  return datesDescription;
};

export const getDoesActivityGroupHaveAddons = (
  activityGroup: ActivityGroup
): boolean => {
  return Boolean(activityGroup?.addOns?.some(addOn => addOn.enabled));
};

export const getAreAllAddonsAlreadyBooked = (
  userBookingItem: BookingItemByActivityGroup,
  client: Client
): boolean => {
  const { activityGroup, ticketItems } = userBookingItem;

  const addOnsForBookingItem: string[] = ticketItems.reduce(
    (ticketItemsAcc, ticketItem) => {
      const addOnsForTicketItem = ticketItem.lineItems.reduce(
        (acc, lineItem) => {
          const activityAddonComps = ticketItem.addOns.map(addOn => {
            const activityId =
              ticketItem.ticket.type === TicketType.Single
                ? ticketItem.activities[0]._id
                : addOn.activity?._id;

            return `${lineItem._id}_${activityId}_${addOn._id}`;
          });

          return acc.concat(activityAddonComps);
        },
        [] as string[]
      );

      return ticketItemsAcc.concat(addOnsForTicketItem);
    },
    [] as string[]
  );

  const availableAddOnsForBookingItem: string[] = ticketItems.reduce(
    (ticketItemsAcc, ticketItem) => {
      const addOnsForTicketItem = ticketItem.lineItems
        .filter(lineItem => !lineItem.cancelled)
        .reduce((acc, lineItem) => {
          let activityAddonComps: string[] = [];

          if (
            ticketItem.ticket.type === TicketType.Single &&
            ticketItem.activities[0].enabled
          ) {
            const activityId = ticketItem.activities[0]._id;

            activityAddonComps = activityGroup.addOns
              ?.filter(addOn => getCanAddOnBeUsedForActivity(addOn, activityId))
              .map(addOn => {
                return `${lineItem._id}_${activityId}_${addOn._id}`;
              }) as string[];
          } else if (
            [TicketType.All, TicketType.Subscription].includes(
              ticketItem.ticket.type
            )
          ) {
            activityAddonComps = activityGroup.addOns?.reduce((acc, addOn) => {
              const addOnCompForEachActivity = ticketItem.activities
                .filter(
                  activity =>
                    getCanAddOnBeUsedForActivity(addOn, activity._id) &&
                    getIsActivitySessionAvailableToPurchase(
                      activity,
                      activityGroup,
                      client
                    )
                )
                .map(
                  activity => `${lineItem._id}_${activity._id}_${addOn._id}`
                );

              acc = acc.concat(addOnCompForEachActivity);

              return acc;
            }, [] as string[]) as string[];
          }

          return acc.concat(activityAddonComps);
        }, [] as string[]);

      return ticketItemsAcc.concat(addOnsForTicketItem);
    },
    [] as string[]
  );

  const areAllAddonsAlreadyBooked = availableAddOnsForBookingItem.every(item =>
    addOnsForBookingItem.includes(item)
  );

  return areAllAddonsAlreadyBooked;
};

export const sortAddons = (
  a: AddOnWithActivity,
  b: AddOnWithActivity
): number => {
  const isDateBefore = isBefore(
    new Date(a.activity?.date?.start as Date),
    new Date(b.activity?.date?.start as Date)
  );

  return isDateBefore ? -1 : 1;
};

export const getActivitiesToAddForAllSessionTicket = (
  activityGroup: ActivityGroup,
  client: Client,
  filterEnabled = false
): Activity<string>[] => {
  const activityGroupActivitiesAfterStartOfDay =
    activityGroup.activities.filter(
      activity =>
        // We still add activities that are disabled but pass in the filterEnabled flag in when checking for availability etc.
        (!filterEnabled || activity.enabled) &&
        isAfterStartOfDay(activity.date.start, client.timeZone) &&
        !getHasClosingTimeForActivitySessionPassed(activity, activityGroup)
    );

  return activityGroupActivitiesAfterStartOfDay;
};

interface GetActivitiesToAddForSubscriptionTicketParams {
  activityGroup: ActivityGroup;
  includeCurrentMonth: boolean;
  client: Client;
  filterEnabled?: boolean;
}

export const getActivitiesToAddForSubscriptionTicket = ({
  activityGroup,
  includeCurrentMonth,
  client,
  filterEnabled = false
}: GetActivitiesToAddForSubscriptionTicketParams): Activity<string>[] => {
  const startOfNextMonth = getStartOfNextMonth(client.timeZone);

  const activityGroupActivitiesAfterStartOfDay =
    activityGroup.activities.filter(activity => {
      const hasNotPassed = isAfterStartOfDay(
        activity.date.start,
        client.timeZone
      );
      const hasClosingTimePassed = getHasClosingTimeForActivitySessionPassed(
        activity,
        activityGroup
      );
      const isAfterStartOfNextMonth = isAfter(
        new Date(activity.date.start),
        startOfNextMonth
      );

      // We still add activities that are disabled but pass in the filterEnabled flag in when checking for availability etc.
      if (filterEnabled && !activity.enabled) {
        return false;
      }

      return (
        hasNotPassed &&
        !hasClosingTimePassed &&
        (includeCurrentMonth || isAfterStartOfNextMonth)
      );
    });

  return activityGroupActivitiesAfterStartOfDay;
};

export const getActivityIdsToAddForAllSessionTicket = (
  activityGroup: ActivityGroup,
  client: Client
): string[] => {
  const activities = getActivitiesToAddForAllSessionTicket(
    activityGroup,
    client
  );
  const activityIds = activities.map(activity => activity._id);
  return activityIds;
};

interface GetActivityIdsToAddForSubscriptionTicketParams {
  activityGroup: ActivityGroup;
  includeCurrentMonth: boolean;
  client: Client;
}

export const getActivityIdsToAddForSubscriptionTicket = ({
  activityGroup,
  includeCurrentMonth,
  client
}: GetActivityIdsToAddForSubscriptionTicketParams): string[] => {
  const activities = getActivitiesToAddForSubscriptionTicket({
    activityGroup,
    includeCurrentMonth,
    client
  });

  const activityIds = activities.map(activity => activity._id);
  return activityIds;
};

export const getActivitiesToChargeSubscriptionCoverFeesFor = (
  activityGroup: ActivityGroup,
  client: Client
): Activity<string>[] => {
  const startOfNextMonth = getStartOfNextMonth(client.timeZone);

  // In this case we only include enabled activities that are before the start of next month
  const activityGroupActivitiesAfterStartOfDay =
    activityGroup.activities.filter(
      activity =>
        activity.enabled &&
        isAfterStartOfDay(activity.date.start, client.timeZone) &&
        !getHasClosingTimeForActivitySessionPassed(activity, activityGroup) &&
        isBefore(new Date(activity.date.start), startOfNextMonth)
    );

  return activityGroupActivitiesAfterStartOfDay;
};

export const sortActivityGroupsByStartDate = (
  a: ActivityGroup,
  b: ActivityGroup
) => {
  // Activity groups should always have at least 1 activity session but this just guards against
  // an empty array here.
  if (!a.activities?.length) return -1;
  if (!b.activities?.length) return 1;

  // Note: this assumes `activities` has already been sorted in date order
  const isActivityGroupBefore = isBefore(
    new Date(a.activities[0].date.start),
    new Date(b.activities[0].date.start)
  );
  return isActivityGroupBefore ? -1 : 1;
};

export const getActivityIdsFromCurrentActivitiesResponse = (
  data: GetActivitySessionsListingResponse[]
) => {
  const activityIds = data[data.length - 1].data.reduce(
    (accumulator, activityDateGroup) => {
      const dateItemActivityIds = activityDateGroup.activities.map(
        activity => activity._id
      );
      return accumulator.concat(dateItemActivityIds);
    },
    [] as string[]
  );

  return activityIds;
};

export const getActivityGroupIdsFromCurrentActivitiesResponse = (
  data: GetActivitySessionsListingResponse[]
): string[] => {
  const activityGroupIds = data[data.length - 1].data.reduce(
    (accumulator, activityDateGroup) => {
      const dateItemActivityGroupIds = activityDateGroup.activities.map(
        activity => activity.activityGroupId
      );
      // Add the dateItemActivityGroupIds to the accumulator Set
      dateItemActivityGroupIds.forEach(id => accumulator.add(id));
      return accumulator;
    },
    new Set<string>()
  );

  // Convert the Set back to an array and return it
  return Array.from(activityGroupIds);
};

export const getActivityGroupIdsFromActivitiesListingResponse = (
  data: GetActivitiesListingResponse[]
) => {
  const activityGroupIds = data[data.length - 1].data.reduce(
    (accumulator, activityGroup) => {
      return accumulator.concat(activityGroup._id);
    },
    []
  );
  return activityGroupIds;
};

export const getActivitySessionFromActivityGroup = (
  activityGroup: ActivityGroup,
  activityId: string
): Activity<string> => {
  const activitySession = activityGroup.activities.find(
    activity => activity._id === activityId
  );

  return activitySession as Activity<string>;
};

export const getRestrictedFieldOptionsToDisplayFromAccessFilters = (
  adminActivityGroupAccessFilters: AdminActivityGroupAccessFiltersFieldData[]
): Hash<string[]> => {
  const restrictedFieldOptions = adminActivityGroupAccessFilters.reduce(
    (acc, item) => {
      if (item.dataType === AccessFilterDataType.RefArray) {
        acc[item.field] = item.valueRefArray || [];
      } else if (item.dataType === AccessFilterDataType.VenueRefArray) {
        acc[item.field] = item.valueRefVenueArray || [];
      }

      return acc;
    },
    {} as Hash<string[]>
  );

  return restrictedFieldOptions;
};

export const getHaveAnyRemainingSessionsSoldOut = (
  activityGroup: ActivityGroup,
  activityGroupSalesData: ActivityGroupSalesData,
  client: Client
) => {
  const haveAnyRemainingSessionsSoldOut = activityGroup.activities
    .filter(activity =>
      getIsActivitySessionAvailableToPurchase(activity, activityGroup, client)
    )
    .some(activity => {
      const activitySales =
        activityGroupSalesData.activitySales[activity._id] || 0;
      return activity.placeLimit && activitySales >= activity.placeLimit;
    });

  return haveAnyRemainingSessionsSoldOut;
};

export const getBookingCloseHoursInputValue = (bookingClosingTime: number) => {
  return Math.floor(bookingClosingTime / 60);
};

export const getBookingCloseMinutesInputValue = (
  bookingClosingTime: number
) => {
  return bookingClosingTime % 60;
};

export const getClosingTimeForActivitySessionAsDate = (
  activity: Activity<string>,
  bookingClosingTime: number
): Date => {
  const closingDate = sub(new Date(activity.date.start), {
    minutes: bookingClosingTime
  });

  return closingDate;
};

export const getHasClosingTimeForActivitySessionPassed = (
  activity: Activity<string>,
  activityGroup: ActivityGroup
): boolean => {
  if (!activityGroup.setBookingClosingTime) {
    return false;
  }

  const closingDate = getClosingTimeForActivitySessionAsDate(
    activity,
    activityGroup.bookingClosingTime as number
  );

  const hasClosingTimePassed = isAfter(new Date(), closingDate);

  return hasClosingTimePassed;
};

export const getIsSessionAvailableForSingleSessionTicket = (
  activity: Activity<string>,
  ticket: Ticket
) => {
  if (ticket.restrictSessions) {
    return ticket.sessionsCanBeUsedFor?.includes(activity._id);
  }

  return true;
};

export const getValidSessionsForTicket = (
  activityGroup: ActivityGroupWithPastActivityIds,
  ticket: Ticket
) => {
  const activitySessionsWithPastFiltered = activityGroup.activities.filter(
    activity =>
      !activityGroup.activitiesPastIds.includes(activity._id) &&
      getIsSessionAvailableForSingleSessionTicket(activity, ticket)
  );

  return activitySessionsWithPastFiltered;
};

export const getMaxAllowedDob = (
  activities: Activity<string>[],
  minAge: AgeRestrictionsAgeValue
) => {
  const earliestActivityDate = getEarliestActivityDate(activities);

  const maxDob = sub(new Date(earliestActivityDate), {
    years: minAge.years,
    months: minAge.months
  });

  return maxDob;
};

export const getMinAllowedDob = (
  activities: Activity<string>[],
  maxAge: AgeRestrictionsAgeValue
) => {
  const latestActivityDate = getLatestActivityDate(activities);

  const minDob = sub(new Date(latestActivityDate), {
    years: maxAge.years,
    months: maxAge.months
  });

  return minDob;
};

export const getAgeRestrictionOnSessionDateReadableString = (
  ageRestrictions: AgeRestrictions,
  key: "minAge" | "maxAge"
) => {
  const { years, months } = ageRestrictions[key] as AgeRestrictionsAgeValue;

  const yearsString = years ? `${years} ${years === 1 ? "year" : "years"}` : "";
  const monthsString = months
    ? `${months} ${months === 1 ? "month" : "months"}`
    : "";
  const separator = years && months ? ", " : "";
  const ageInformation = `${yearsString}${separator}${monthsString}`;

  return `${ageInformation}`;
};

export const checkAgeEligibility = (
  attendee: Attendee | null,
  activityGroup?: ActivityGroup,
  activities?: Activity<string>[]
) => {
  if (!attendee || !activityGroup || !activities)
    return {
      isAttendeeDobOutsideAgeRestriction: false,
      shouldBlockAttendeeIfOutsideAgeRestriction: false
    };

  let isAttendeeTooYoung = false;
  let isAttendeeTooOld = false;
  const shouldBlockAttendeeIfOutsideAgeRestriction =
    activityGroup.ageRestrictions?.handling === AgeRestrictionHandling.Block;

  if (attendee && activityGroup.ageRestrictions) {
    const attendeeDob = attendee.fieldData.find(
      item => item.field?.internalKey === "attendee_dob" && item.field.enabled
    )?.value;

    if (!attendeeDob) {
      return {
        isAttendeeDobOutsideAgeRestriction: false,
        shouldBlockAttendeeIfOutsideAgeRestriction
      };
    }

    const { criteria, minAge, maxAge, minDob, maxDob } =
      activityGroup.ageRestrictions;

    if (criteria === AgeRestrictionCalculationCriteria.AgeOnSessionDate) {
      if (minAge?.years || minAge?.months) {
        const maxAllowedDob = getMaxAllowedDob(activities, minAge);
        isAttendeeTooYoung = isAfter(new Date(attendeeDob), maxAllowedDob);
      }
      if (maxAge?.years || maxAge?.months) {
        const minAllowedDob = getMinAllowedDob(activities, maxAge);
        isAttendeeTooOld = isBefore(new Date(attendeeDob), minAllowedDob);
      }
    } else if (criteria === AgeRestrictionCalculationCriteria.BirthDate) {
      if (maxDob) {
        isAttendeeTooYoung = isAfter(new Date(attendeeDob), new Date(maxDob));
      }
      if (minDob) {
        isAttendeeTooOld = isBefore(new Date(attendeeDob), new Date(minDob));
      }
    }
  }

  const isAttendeeDobOutsideAgeRestriction =
    isAttendeeTooYoung || isAttendeeTooOld;

  return {
    isAttendeeDobOutsideAgeRestriction,
    shouldBlockAttendeeIfOutsideAgeRestriction
  };
};

export const getIsAttendeeOutsideAgeRestriction = (
  attendee: Attendee,
  activityGroup: ActivityGroup,
  activities: Activity<string>[]
) => {
  const { isAttendeeDobOutsideAgeRestriction } = checkAgeEligibility(
    attendee,
    activityGroup,
    activities
  );

  return isAttendeeDobOutsideAgeRestriction;
};
